<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';

	export default {
		name: "BoUsers",
		extends: Base,
		data() {
			return {
				Name: "BoUsers",
				mrLevel: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'(v) {
				if (!v) return
				this.search()
			},
			'filter.search'(v) {
				this.search()
			}
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		<!-- <div class="row page-titles">
			<div class="col-md-5 align-self-center">
				<h4 class="text-themecolor">{{page.PageName||"-"}}</h4>
			</div>
			<div class="col-md-7 align-self-center text-right">
				<div class="d-flex justify-content-end align-items-center">
					<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<router-link :to="{name:'BoDashboard'}">Home</router-link>
						</li>
						<li v-if="page.PageName != 'Home'" class="breadcrumb-item">{{page.PageName||"-"}}</li>
						<li v-if="page.PageName != 'Home'" class="breadcrumb-item active">{{page.ObjectName||"-"}}</li>
					</ol>
					<router-link v-if="page.Name && $route.params.id" :to="{name:page.Name}"
						class="btn btn-success d-none d-lg-block m-l-15">
						<i class="fas fa-list-ul m-r-15"></i>List {{page.ObjectName}}
					</router-link>
					<router-link v-if="page.AddItem && !$route.params.id && page.moduleRole('Add')"
						:to="{name:page.Name,params:{id:'add'}}" class="btn btn-info d-none d-lg-block m-l-15"> <i
							class="fa fa-plus-circle  m-r-15"></i>Add User </router-link>
				</div>
			</div>
		</div> -->

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row gutter-10">
								<div class="col-sm-2">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-lg-3 col-sm-4 ml-auto">
									<select2 :options="mrLevel" :object="['bul_id','bul_name']" v-model="filter.level">
										<option value="">-- Select Level --</option>
									</select2>
								</div>
								<div class="col-9 col-sm-4 col-lg-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
												placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-3 col-sm-auto">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="bu_full_name"></SortField>
									</th>
									<th>{{fields.bu_level}}</th>
									<th>{{fields.bu_is_active}}</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.bu_full_name}}</td>
									<td>{{v.levelname}}</td>
									<td>
										<StatusLabel :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action">
										<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
										<a v-if="page.moduleRole('ChangeStatus')" href="javascript:;" class="icon_action"
											@click="changeStatus(k)" v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
										<a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action"
											@click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Pagination class="justify-content-end mt-3 mb-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" User"}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-5">
							<BoField name="bu_full_name" v-model="row.bu_full_name"></BoField>
							<BoField name="bu_email_address" onkeydown="return emailKey(event)" v-model="row.bu_email_address" :attr="{type:'email'}"></BoField>
							<BoField name="bu_username" onkeydown="return alphaOnly(event)" v-model="row.bu_username" keyFilter="usernameKey">
								<span slot="help" class="help-block">
									<small>{{ fields.bu_username }} for login to Backoffice page</small>
								</span>
							</BoField>
							<BoField name="bu_password" v-model="row.bu_password" :attr="{type:'password'}"></BoField>
						</div>
						<div class="col-sm-4">
							<BoField name="bu_phone" :attr="{type:'number'}" v-model="row.bu_phone"></BoField>
							<BoField name="bu_level">
								<select2 name="bu_level" v-bind="validation('bu_level')" :options="mrLevel"
									:object="['bul_id','bul_name']" v-model="row.bu_level">
									<option value="">-- Select Level --</option>
								</select2>
							</BoField>
							<div class="row">
								<div class="col-7">
									<BoField name="bu_is_active">
										<radio name="bu_is_active" v-model="row.bu_is_active" option="Y"
											:attr="validation('bu_is_active')">Active</radio>
										<radio name="bu_is_active" v-model="row.bu_is_active" option="N">Inactive</radio>
									</BoField>
								</div>
							</div>
						</div>
						<div class="col-sm-3">
							<BoField name="bu_pic">
								<Uploader name="bu_pic" type="users" uploadType="cropping" :param="{thumbnail:true}"
									v-model="row.bu_pic"></Uploader>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-loading">{{($route.params.id=='add'?'Add':'Update')+" User"}}</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>